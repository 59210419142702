@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@layer utilities {
  .border-gradient-golden {
    border: 4px solid;
    border-image-source: linear-gradient(to right, #e0aa3e, #f9f295);
    border-image-slice: 1;
  }

  
}

@layer components {
  .golden-bg {
    @apply shadow-sm shadow-yellow-600 bg-clip-padding bg-gradient-to-r from-[#E0AA3E] to-[#F9F295];
  }

  .green-bg {
    @apply shadow-sm shadow-yellow-600 bg-clip-padding bg-gradient-to-r from-[#2dd82f] to-[#11be67];
  }

  .gray-bg {
    @apply shadow-sm shadow-gray-600 bg-clip-padding bg-gradient-to-r from-[#6e7370] to-[#37433d];
  }

  .golden-text {
    @apply text-transparent bg-clip-text bg-gradient-to-t from-[#E0AA3E] to-[#F9F295] truncate;
  }

  .input_class {
    @apply block w-full px-3 py-1.5 text-base font-semibold  text-yellow-600  bg-slate-800 rounded transition ease-in-out m-0 focus:bg-slate-900 focus:ring-slate-900 focus:ring-0 focus:outline-none;
  }

  .submit_button {
    @apply shadow-sm shadow-yellow-600 font-semibold outline-none cursor-pointer active:scale-125 active:duration-500 flex justify-evenly items-center w-36 h-10 py-2 px-7 rounded-2xl text-gray-700 golden-bg hover:scale-110 duration-150;
  }

  .green_button {
    @apply shadow-sm shadow-green-600 font-semibold outline-none cursor-pointer active:scale-125 active:duration-500 flex justify-evenly items-center w-36 h-10 py-2 px-7 rounded-2xl text-gray-700 green-bg hover:scale-110 duration-150;
  }

  .gray_button {
    @apply shadow-sm shadow-gray-600 font-semibold outline-none cursor-pointer active:scale-125 active:duration-500 flex justify-evenly items-center w-36 h-10 py-2 px-7 rounded-2xl text-gray-400 gray-bg hover:scale-110 duration-150;
  }


  .delete_button {
    @apply shadow-sm shadow-red-600 font-semibold outline-none cursor-pointer active:scale-125 active:duration-500 flex justify-evenly items-center w-36 h-10 py-2 px-7 rounded-2xl text-black bg-red-600 hover:scale-110 duration-150;
  }

  .pointing_scale {
    @apply hover:scale-110 duration-150;
  }

  .icon_class {
    @apply mr-3 flex-shrink-0 h-6 w-6;
  }

  .whatsapp_button {
    @apply shadow-sm shadow-green-600 font-semibold outline-none cursor-pointer active:scale-125 active:duration-500 flex justify-around items-center w-[90%] h-5 my-2 rounded-2xl text-gray-700 green-bg hover:scale-110 duration-150;
  }
}
